import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Privacypolicy = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                                <a href="/">
                                    <h3>  Insurance File Claim</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:+18007563256">+18007563256</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Privacy Policy</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h4>Privacy Policy</h4>
                            <p>Welcome to Insurance-File-Claim.com, a car accident claim provider in the USA. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website.</p>
                            <h4>1.Information We Collect</h4>
                            We may collect personal information that you provide directly to us when you use our services, including:
                            <ul>
                                <li><b>Personal Identification Information:</b> Name, address, email address, phone number, date of birth, and national insurance number.</li>
                                <li><b>Accident Information:</b> Details about the car accident, including date, time, location, and any injuries sustained.</li>
                                <li><b>Vehicle Information:</b> Make, model, registration number, and insurance details</li>
                                <li><b>Communication Data:</b> Records of your correspondence with us, including any feedback or complaints.</li>
                                <li><b>Technical Information:</b> IP address, browser type, access times, and pages viewed on our website.</li>
                            </ul>
                            <h4>2. How We Use Your Information</h4>
                            <p>We use the information we collect for the following purposes:</p>
                            <ul>
                                <li><b>To Process Your Claim:</b> Use the information provided to evaluate and process your car accident claim.</li>
                                <li><b>TCommunication:</b> To contact you regarding your claim, provide updates, respond to inquiries, or resolve any issues.</li>
                                <li><b>Improvement of Services:</b> To enhance our website, services, and customer support based on your feedback and usage patterns.</li>
                                <li><b>Legal Compliance:</b> To comply with applicable laws, regulations, and legal obligations</li>
                            </ul>
                            <h4>3. Disclosure of Your Information</h4>
                            <p>We may share your information with third parties under the following circumstances:</p>
                            <ul>
                                <li><b>With Your Consent:</b> We may share your information with third parties if you have given us explicit consent to do so.</li>
                                <li><b>Service Providers:</b> We may disclose information to service providers that perform services on our behalf, such as legal services, claim processors, and IT support.</li>
                                <li><b>Legal Requirements:</b> We may disclose your information if required to do so by law or in response to valid requests by public authorities (e.g., a court or government agency).</li>
                            </ul>
                            <h4>4. Data Security</h4>
                            <p>We take appropriate technical and organizational measures to protect your personal information against unauthorized access, loss, misuse, or alteration. However, please note that no method of transmission over the Internet or method of electronic storage is 100% secure.</p>
                            <h4>5. Your Rights</h4>
                            <p>Under the General Data Protection Regulation (GDPR), you have the right to:</p>
                            <ul>
                                <li><b>Access Your Data:</b> Request a copy of the personal data we hold about you.</li>
                                <li><b>Rectify Your Data:</b> Request that we correct any inaccuracies in your personal data.</li>
                                <li><b>Erase Your Data:</b> Request that we delete your personal data under certain circumstances.</li>
                                <li><b>Restrict Processing:</b> Request that we restrict the processing of your personal data</li>
                                <li><b>Object to Processing:</b> Object to the processing of your personal data in certain situations.</li>
                                <li><b>Data Portability:</b> Request the transfer of your data to another party.</li>
                            </ul>
                            <p>To exercise these rights, please contact us at <a href="mailto:info@ insurance-file-claim.com">info@ insurance-file-claim.com</a></p>
                            <h4>6. Cookies and Tracking Technologies</h4>
                            <p>Our website may use cookies and similar tracking technologies to enhance the user experience and analyze website traffic. You can choose to disable cookies through your browser settings; however, this may affect your ability to use certain features of our website.</p>
                            <h4>7. Third-Party Websites</h4>
                            <p>Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of these websites. We encourage you to read the privacy policies of any third-party sites you visit.</p>
                            <h4>8. Children’s Privacy</h4>
                            <p>Our services are not directed to individuals under the age of 18. We do not knowingly collect personal information from children. If we become aware that we have inadvertently received personal information from a child, we will delete such information from our records.</p>
                            <h4>9. Changes to This Privacy Policy</h4>
                            <p>We may update this privacy policy from time to time. We will notify you of any significant changes by posting the new policy on this page and updating the “Last Updated” date. We encourage you to review this privacy policy periodically to stay informed about how we protect your information.</p>
                            <h4>10. Contact Us</h4>
                            <p>If you have any questions or concerns about this privacy policy or our data practices, please contact us at:<a href="mailto:info@ insurance-file-claim.com">info@ insurance-file-claim.com</a></p>
                            <p>By using our website, you consent to the collection, use, and sharing of your information as described in this Privacy Policy.</p>
                            <h4>How we use your personal information </h4>
                            <p>We use the information we collect about you, including personal details, for the following purposes:</p>
                            <ul>
                                <li>Presenting our website and its content to you.</li>
                                <li>Providing you with information, products, or services that you request from us.</li>
                                <li>Fulfilling any other purposes for which you provide the information.</li>
                                <li>Carrying out our obligations and enforcing our rights under any contracts between you and us. </li>
                                <li>Notifying you about changes to our website or any products or services offered through it.</li>
                                <li>Using the information in any other manner described at the time you provide it.</li>
                                <li>For any other purposes with your consent.</li>
                            </ul>
                            <h4>With whom we share your information </h4>
                            <p>We may disclose personal information that we collect or you provide as described in this privacy policy. We may share your personal information with third parties who assist us in providing our services. These service providers include legal representatives, medical professionals, repair centres and our solicitor’s panel. We may also share your personal information under the following circumstances:</p>
                            <ul>
                                <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganisation, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our website users is among the assets transferred.</li>
                                <li>To fulfil the purpose for which you provide it.</li>
                                <li>For any other purpose disclosed by us when you provide the information.</li>
                            </ul>
                            <h4>How we keep your information secure </h4>
                            <p>We have implemented strong security measures and protocols to protect your personal information. However, no transmission of information over the Internet is 100% secure. So, we cannot guarantee the security of information transmitted over the Internet. </p>
                            <h4>Your rights </h4>
                            <p>You have certain rights with respect to your personal information. These rights include:</p>
                            <ul>
                                <li>Right to access personal information we hold about you and to ask that your information be corrected if it is inaccurate or incomplete.</li>
                                <li>Right to receive the personal information you provide to us in a structured, commonly used, and machine-readable format.</li>
                                <li>Right to withdraw your consent at any time. </li>
                                <li>Right to object to our processing of your personal data where we are relying on a legitimate interest (or those of a third party), and there is something about your particular situation which makes you want to object to processing on this ground. </li>
                                <li>Right to object where we are processing your personal data for direct marketing purposes.</li>
                                <li>Right to request that we erase your personal information in certain circumstances, such as when it is no longer necessary for the purposes for which it was collected, or when you withdraw consent and there are no other legal grounds for processing.</li>
                                <li>Right to request the restriction of processing of your personal information in certain circumstances, such as when you contest the accuracy of the data or when the processing is unlawful.</li>
                            </ul>
                            <h4>Changes to the privacy policy </h4>
                            <p>In our sole discretion, we reserve the right to make changes to our privacy policy from time to time. You’re advised to visit this page frequently to stay updated with the changes in our privacy policy. </p>
                            <h4>Contact us </h4>
                            <p>If you have questions or queries about our privacy policy, don’t hesitate to contact us. Feel free to reach out to us at <a href="mailto:info@insurance-file-claim.com">info@ insurance-file-claim.com</a></p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <ul>
                                <li><a href="/privacy-policy">Privacy Policy</a></li>
                                <li><a href="/terms-conditions">Terms & Conditions</a></li>
                                <li><a href="/Contact">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <p className="home2-colpyy">
                                © Copyright 2024 <a href="/">insurance-file-claim</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <p className="colpyy">
                            © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section> */}
        </>
    );
};

export default Privacypolicy;
