import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const Californiacontact = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                            <a href="https://insurance-file-claim.com/california">
                                    <h3>  Insurance File Claim</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:+1-888-724-0790">+1-888-724-0790</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-md-12 col-12" >
                            <div className="content-box">
                                <h1>Contact Us For Efficient Claim Management</h1>
                                <p className="accident">Our committed team is available to offer dependable support throughout the process of claim processing. </p>
                                <h3 className="touch">Request a callback </h3>
                                <div className="settlement">
                                    <div className="cont-form" id="contv">
                                        <form action="Service.php" method="post" enctype="multipart/form-data">
                                            <div className="row">
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="name" className="form-control" placeholder="Name" required />
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                                    <input type="email" name="email" className="form-control" placeholder="Email" required />
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                                    <input type="tel" name="phone" className="form-control" placeholder="Phone" required />
                                                </div>
                                                <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-12">
                                                    <input type="text" name="subject" className="form-control" placeholder="Subject" required />

                                                </div>
                                                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12">
                                                    <textarea id="message" title="Message" name='message' placeholder="How can we help you?" required />
                                                    <div className="form-btn">
                                                        <input type="submit" className="form-rol" defaultValue="send" />
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <ul>
                                <li><a href="/california-privacy-policy">Privacy Policy</a></li>
                                <li><a href="/california-terms-conditions">Terms & Conditions</a></li>
                                <li><a href="/california-contact">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <p className="home2-colpyy">
                                © Copyright 2024 <a href="https://insurance-file-claim.com/california">insurance-file-claim</a>. All Rights
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Californiacontact;
