import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";
import call2 from "../assets/images/call2.png";

import home5bnr from "../assets/images/home5bnr.webp";

const Home5 = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                                <a href="/">
                                <h3>  Insurance File Claim</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:+18007563256">+18007563256</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="home5-section">
            <img src={home5bnr} className="home5bnr" alt="" />
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-md-12 col-12" >
                            
                            <div className="home5-bnr-content">
                                <h1>Car Insurance Claims Made Efficient</h1>
                                <p className="text-white">We help drivers across the US claim car insurance with ease.  </p>
                                <div className="claim-list">
                                    <ul>
                                        <li><span>1</span> Upload photos</li>
                                        <li><span>2</span> Schedule repairs.</li>
                                        <li><span>3</span> View status.</li>
                                       </ul>
                                </div>
                            </div>
                        </div>
                        
                        
                        <div className="outerhome-5">
                        <ul className="home5-ul">
                            <li> <div className="home2-cta1-content">
                                <h5 className="Road">Make a claim </h5>
                                <p className="small">Get in touch with our solicitors for expert advice.  </p>
                                <div className="home2-btn">
                                    <a className="home2-call" href="tel:+18007563256"><span ><img src={call2} className="home2call" alt="" /></span>+18007563256</a>
                                </div>

                            </div></li>
                            <li>   <div className="home2-cta1-content">
                                <h5 className="Road">Seek Immediate assistance </h5>
                                <p className="small">Call our team for quick roadside assistance.  </p>
                                <div className="home2-btn">
                                    <a className="home2-call2" href="tel:+18007563256"><span ><img src={call} className="home2call" alt="" /></span>+18007563256</a>
                                </div>

                            </div></li>
                        </ul>
                        </div>
                    
                    </div>
 
                    </div>
                 
              
            </section>
            <section className="home2-copy-right">
    <div className="container">
        <div className="row">
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
                <ul>
                    <li><a href="/privacy-policy">Privacy Policy</a></li>
                    <li><a href="/terms-conditions">Terms & Conditions</a></li>
                    <li><a href="/contact">Contact Us</a></li>
                </ul>
            </div>
            <div className="col-xxl-6 col-xl-6 col-md-12 col-12">
            <p className="home2-colpyy">
            © Copyright 2024 <a href="/">insurance-file-claim</a>. All Rights Reserved.
              </p>
            </div>
        </div>
    </div>
</section>
        </>
    );
};

export default Home5;
