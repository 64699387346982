import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";

const CaliforniaTermsconditions = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                            <a href="https://insurance-file-claim.com/california">
                                    <h3>  Insurance File Claim</h3>
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:+1-888-724-0790">+1-888-724-0790</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="privacy-policy">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <div className="headw">
                                <h2>Terms & Conditions</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="main-content">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                            <h4>Terms of use </h4>
                            <p>Welcome to Insurance-File-Claim.com. These Terms and Conditions govern your use of our website and services. By accessing or using our website, you agree to be bound by these Terms and Conditions. Please read them carefully before using our services.</p>
                            <h4>1. Introduction</h4>
                            <p>Insurance-File-Claim.com is a car accident claim provider in the USA. We assist individuals in filing and managing claims related to car accidents. These Terms and Conditions outline the rules and regulations for the use of our website and services.</p>
                            <h4>2. Eligibility</h4>
                            <p>By using our website, you confirm that you are at least 18 years old and have the legal capacity to enter into these Terms and Conditions. If you are under 18, you must not use this website or provide us with any personal information.</p>
                            <h4>3. Use of Our Services</h4>
                            <ul>
                                <li><b>Purpose:</b> Our services are intended to assist users in filing car accident claims and navigating the claims process.
                                </li>
                                <li><b>Account Registration:</b> To access certain services, you may be required to create an account. You agree to provide accurate and complete information during registration and to keep this information up-to-date.
                                </li>
                                <li><b>Prohibited Conduct:</b> You agree not to use our website or services for any illegal or unauthorized purpose, including but not limited to:
                                    <ol>
                                        <li>Submitting false or misleading information.</li>
                                        <li>Engaging in any activity that could harm our website, services, or reputation.</li>
                                        <li>Attempting to gain unauthorized access to any portion of our website or services.</li>
                                    </ol>
                                </li>
                            </ul>
                            <h4>4. Intellectual Property</h4>
                            <p>All content on this website, including text, graphics, logos, and software, is the property of Insurance-File-Claim.com or its licensors and is protected by copyright, trademark, and other intellectual property laws. You may not reproduce, distribute, modify, or create derivative works from any content without our express written permission.</p>
                            <h4>5. Disclaimers and Limitation of Liability</h4>
                            <p><b>No Legal Advice:</b> The information provided on our website is for informational purposes only and does not constitute legal advice. You should consult with a qualified legal professional regarding your specific situation.
                            </p>
                            <p><b>Service Availability:</b> We strive to provide reliable and uninterrupted access to our services. However, we do not guarantee that our website or services will be available at all times or that they will be free from errors, viruses, or other harmful components.</p>
                            <p><b>Limitation of Liability:</b> To the fullest extent permitted by law, Insurance-File-Claim.com shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or related to your use of our website or services.</p>
                            <h4>6. Indemnification</h4>
                            <p>You agree to indemnify, defend, and hold harmless Insurance-File-Claim.com, its affiliates, and their respective officers, directors, employees, and agents from and against any claims, losses, liabilities, expenses, damages, and costs (including reasonable attorney’s fees) arising out of or related to your use of our website or services, your violation of these Terms and Conditions, or your infringement of any intellectual property or other rights of any person or entity.</p>
                            <h4>7. Privacy Policy</h4>
                            <p>Your use of our website is also governed by our <a href="/california-privacy-policy">Privacy Policy</a>, which is incorporated into these Terms and Conditions by reference. Please review our Privacy Policy to understand our practices regarding the collection, use, and disclosure of your personal information.</p>
                            <h4>8. Governing Law and Jurisdiction</h4>
                            <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of the United States. Any disputes arising out of or relating to these Terms and Conditions shall be subject to the exclusive jurisdiction of the courts of the United States.</p>
                            <h4>9. Termination</h4>
                            <p>We reserve the right, in our sole discretion, to terminate or suspend your access to our website or services at any time, without notice, for any reason, including, without limitation, a breach of these Terms and Conditions.</p>
                            <h4>10. Changes to These Terms and Conditions</h4>
                            <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and the “Last Updated” date will be revised. Your continued use of our website or services after any changes have been made constitutes your acceptance of the new Terms and Conditions.</p>
                            <h4>11. Contact Us</h4>
                            <p>If you have any questions or concerns about these Terms and Conditions, please contact us at: <a href="mailto:info@insurance-file-claim.com">info@ insurance-file-claim.com</a></p>
                            <p>By using our website, you agree to these Terms and Conditions.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="home2-copy-right">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <ul>
                            <li><a href="/california-privacy-policy">Privacy Policy</a></li>
                                <li><a href="/california-terms-conditions">Terms & Conditions</a></li>
                                <li><a href="/california-contact">Contact Us</a></li>
                            </ul>
                        </div>
                        <div className="col-xxl-6 col-xl-6 col-md-6 col-12">
                            <p className="home2-colpyy">
                            © Copyright 2024 <a href="https://insurance-file-claim.com/california">insurance-file-claim</a>. All Rights 
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default CaliforniaTermsconditions;
