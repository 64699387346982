import React from "react";
import mainlogo from "../assets/images/mainlogo.png";
import call from "../assets/images/call.png";
import caraccident from "../assets/images/caraccident.png";
import male from "../assets/images/male.png";
import female from "../assets/images/female.png";


const Home4 = () => {
    return (
        <>
            <section className="myheader">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-7 col-xl-7 col-md-6 col-12">
                            <div className="mylogo">
                                <a href="/">
                                    <img src={mainlogo} className="mainlogo" alt="logo" />
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5 col-xl-5 col-md-6 col-12">
                            <div className="calling">
                                <div class="headercnt">
                                    <div class="chatmsg">
                                        <img src={call} className="chatmsg" alt="" />
                                    </div>
                                    <div class="box-body">
                                        <h3 class="nav-info-box-title">Call Us Now</h3>
                                        <p><a href="tel:0333 000 000">0333 000 000</a></p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="Home4-section">
                <div className="container">
                    <div className="row text-center">
                        <div className="col-xxl-12 col-md-12 col-12" >
                            <div className="home4-content">
                                <h1 className="hr-lines">Road Accident <span className="claimspan">Claims</span> Management</h1>
                                <p className="guidance">Make a successful claim with the guidance of our UK-based no-fault claim experts.</p>
                             
                            </div>
                        </div>
                        </div>
                        <div className="row text-center">
                            <div className="col-xxl-12 col-md-12 col-12" >
                                <div className="outer-big">
                                    <ul className="biglist">
                                        <li>
                                            <div className="cta-box">
                                                <span className="imgspan">
                                                    <img src={male} className="male" alt="" />
                                                </span>
                                                <div className="ctahome4">
                                                    <p>
                                                        <span className="icon-pic"><img src={call} alt="" /></span>
                                                        <p><span>  <small>Start Your Claim Today</small><br />
                                                        </span>

                                                            <a href="/">+1 (800) 756-32-56</a></p></p>

                                                </div>
                                            </div>
                                            </li>
                                            <li>
                                            <div className="cta-box">
                                                <span className="imgspan">
                                                    <img src={female} className="male" alt="" />
                                                </span>
                                                <div className="ctahome4">
                                                    <p>
                                                        <span className="icon-pic"><img src={call} alt="" /></span>
                                                        <p><span>  <small>Start Your Claim Today</small><br />
                                                        </span>

                                                            <a href="/">+1 (800) 756-32-56</a></p></p>

                                                </div>
                                            </div>
                                            </li>
                                      

                                    </ul>
                                </div>
                            </div>

                        </div>
                        <img src={caraccident} className="caraccident" alt="" />
                        <p className="more">
                        We take your privacy seriously. For more information, please see our <span className="colorspan"><a className="contacted" href="/privacy-policy">Policy Policy</a> & <a className="contacted" href="/terms-conditions">Terms</a>.<br /> <a className="contacted" href="/Contact">Contact us </a></span>today to speak to an experienced accident claim specialist.  
                        </p>
                  
                </div>
            </section>
            <section className="copy-right01">
                <div className="container">
                    <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-md-12 col-12">
                    
                          <p className="colpyy">
                             © Copyright 2024 <a href="/">Insurance claims online</a>. All Rights Reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default Home4;
